// vue
import { ref, reactive, computed, watch, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useMarketplace } from '@store/ts/marketplace';

// services
import Marketplace from '@services/marketplace';
import LocalStorage from '@services/ts/localStorage';

// router
import { useRouter } from 'vue-router';

// i18n
import { useI18n } from 'vue-i18n';

// shared
import { CRUD_PAGER_FACTORY } from '@shared/factories';

// UI
import { useMessage } from 'naive-ui';

export default function (props, context) {
    // store
    const gl = useGl();
    const refs = useRefs();
    const marketplace = useMarketplace();

    // router
    const router = useRouter();

    // i18n
    const { t } = useI18n();

    // ui
    const message = useMessage();
      
    // vars
    const { _ } = window;
    const mentorId = ref(9);
    const init = ref(false);
  
    const showModalHelp = ref(false);
    watch(showModalHelp, v => {
        if (!v) {
            needHelpModel.subject.value = '';
            needHelpModel.question.value = '';
            needHelpModel.contact.value = '';
            needHelpModel.agree.value = false;
        };
    });

    const needHelpLoading = ref(false);
    const requestMentorshipNotes = ref();

    // storage filters
    const storeFilterKey = '___filters_marketplace-mentors';
    let storeFilters = LocalStorage.getItem(storeFilterKey);

    // set filter settings
    if (storeFilters == null) {
        LocalStorage.setItem(storeFilterKey, CRUD_PAGER_FACTORY({}));

        storeFilters = LocalStorage.getItem(storeFilterKey);
    }

    // filters
    const filters = reactive({
        ...storeFilters,
    });

    const needHelpModel = reactive({
        subject: {
            value: '',
            placeholder: marketplace.localization['marketplace/mentors/dialogs/subject'],
            status: undefined,
            msg: undefined,
        },
        question: {
            value: '',
            placeholder: marketplace.localization['marketplace/mentors/dialogs/question'],
            status: undefined,
            msg: undefined,
        },
        contact: {
            value: '',
            placeholder: marketplace.localization['marketplace/mentors/dialogs/contact'],
            status: undefined,
            msg: undefined,
        },
        agree: {
            value: false,
            placeholder: marketplace.localization['marketplace/mentors/dialogs/license_agreement'],
            status: undefined,
            msg: undefined,
        },
    });

    const editorOptions = computed(() => ({
        height: gl.isMobile ? 200 : 300,
        menubar: false,
        auto_focus: false,
        placeholder: needHelpModel.question.placeholder,
        skin: gl.darkTheme ? 'oxide-dark' : '',
        content_css: gl.darkTheme ? 'dark' : '',
        plugins: [
            'autolink lists link anchor code',
        ],
        toolbar:
          'undo redo | formatselect | bold italic | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | code',
    }));

    const buttonDisabled = computed(() =>
        !needHelpModel.subject.value || !needHelpModel.question.value ||
      !needHelpModel.contact.value);
    // || !needHelpModel.agree.value
  

    const actions = computed(() => ['marketplace_mentors__need_help']);
    const parsed = computed(() => {
        var arr = marketplace.localization['marketplace/mentorship/info'].split(/></g);

        //split removes the >< so we need to determine where to put them back in.
        for(var i = 0; i < arr.length; i++){
            if(arr[i].substring(0, 1) != '<'){
                arr[i] = '<' + arr[i];
            }

            if(arr[i].slice(-1) != '>'){
                arr[i] = arr[i] + '>';
            }
        }

        return arr;
    });

    const getData = async () => {
        gl.showLoading = true;

        // save filters
        LocalStorage.setItem(storeFilterKey, filters);

        try {
            const prepare = await Marketplace.mentorshipGet({
                pager: filters,
            });

            if (marketplace.mentorshipGetNotEmpty) {
                Object.keys(prepare.data).forEach(key => {
                    if (!['columns'].includes(key)) {
                        marketplace.mentorshipGet[key] = prepare.data[key];
                    }
                });
            } else {
                marketplace.mentorshipGet = prepare.data;
            }
        } catch {
            message.error(t('errorMessage'));
        };

        gl.showLoading = false;
    };

    const changeFilter = ([ key, value ], update = false) => {
        const canUpdate = key === 'query' && !value && marketplace.mentorshipGet.pager.query;

        if (key !== 'page') {
            _.set(filters, 'page', 1);
        }

        if (key === 'perPage') {
            LocalStorage.setItem('__filters_marketplace-mentors', value);
        }

        _.set(filters, key, value !== null && value !== undefined ? value : [ -1 ]);
      
        if (update || canUpdate)
            getData();
    };

    const requestMentorship = async () => {
        if (!needHelpModel.agree.value) {
            needHelpModel.agree.status = 'error';

            return;
        }

        needHelpLoading.value = true;

        const payload = {
            mentor_id: mentorId.value,
            contact: needHelpModel.contact.value,
            subject: needHelpModel.subject.value,
            question: needHelpModel.question.value,
            _formPath: 'marketplace.mentorship.mentors.requestMentorship',
        };

        const prepare = await Marketplace.requestMentorship(payload);

        if (!prepare.data.status) {
            if (prepare.data?.errors_form) {

                for (let key in needHelpModel) {
                    const fields = prepare.data.errors_form['marketplace.mentorship.mentors.requestMentorship'].fields;
                    const el = Object.keys(fields).find(el => el === key);

                    if (el) {
                        needHelpModel[key].status = 'error';
                        needHelpModel[key].msg = fields[el].msg;
                    } else {
                        needHelpModel[key].status = 'success';
                        needHelpModel[key].msg = undefined;
                    }
                }
            }

            if (prepare.data?.errors) {
                prepare.data.errors.forEach(({ msg }) => {
                    message['error'](msg);
                });
            }
        } else {
            showModalHelp.value = false;
            // questionId будет в строке поиска на странице /office/#/marketplace/mentorship/my-mentors?question_id=532
            router.push({name: 'marketplace.mentorship.myMentors', query: { questionId: prepare.data.question_id }});
        }

        needHelpLoading.value = false;
    };

    const doSomething = ([ action, value ]) => {
        const fns = {
            needHelp: () => {
                mentorId.value = value;

                let mentor = marketplace.mentorshipRecords.filter(({ id }) => id == mentorId.value)[0];
                let notes = marketplace.localization['marketplace/mentors/dialogs/request_mentorship_notes'];

                Object.keys(mentor)
                    .forEach(key => notes = notes.split('{' + key + '}')
                        .join(mentor[key]));

                requestMentorshipNotes.value = notes;
                showModalHelp.value = true;
            },
        };

        fns[action]();
    };

    const sortColumn = $event => {
        _.set(
            filters,
            'sort_column',
            $event.columnKey !== undefined && $event.order !== undefined
                ? $event.columnKey
                : '');

        _.set(
            filters,
            'sort_dir',
            $event.order !== undefined
                ? $event.order
                : '');

        getData();
    };
      
    onMounted( async () => {
        await getData();

        init.value = true;
    });

    return {
        gl,
        refs,
        init,
        parsed,
        filters,
        editorOptions,
        marketplace,
        showModalHelp,
        needHelpModel,
        buttonDisabled,
        needHelpLoading,
        requestMentorshipNotes,
        actions,
        getData,
        sortColumn,
        doSomething,
        changeFilter,
        requestMentorship,
    };
}