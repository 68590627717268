<template>
<n-alert v-if="marketplace.localization['marketplace/mentorship/info']" class="rounded-lg" type="warning" size="large">
    <template #header>
        <n-collapse arrow-placement="right">
            <n-collapse-item>
                <template #header>
                    <div class="text-xs" v-html="parsed[0]"></div>
                </template>
                <div
                    class="notes text-xs"
                    v-html="parsed.slice(1).join('')">
                </div>
            </n-collapse-item>
        </n-collapse>
    </template>
</n-alert>

<div v-if="init">
    <super-table
        showSearch
        :filters="filters"
        :actions="actions"
        :pager="marketplace.mentorshipPager"
        :columns="marketplace.mentorshipColumns"
        :records="marketplace.mentorshipRecords"
        columnsStorageName="__columns_marketplace-mentors"
        type="marketplace.mentorship"
        @getData="getData"
        @sortColumn="sortColumn"
        @doSomething="doSomething"
        @applyFilters="changeFilter">
        <template #tableTitle>
            <div class="text-base text-left mb-2">
                {{ marketplace.localization['marketplace/mentors/grid/title'] }}
                <span class="text-main font-semibold text-lg">{{ marketplace.mentorshipTotal }}</span>
            </div>
        </template>
    </super-table>
</div>

<base-modal-desktop
    lightDark
    :label="marketplace.localization['marketplace/mentors/grid/actions/inquire_mentorship']"
    v-model:show="showModalHelp">
    <template #default>
        <n-alert class="rounded-lg" type="warning" size="large">
            <template #header>
                <n-collapse arrow-placement="right">
                    <n-collapse-item>
                        <template #header>
                            <div class="text-xs" v-html="marketplace.localization['marketplace/mentors/dialogs/request_mentorship_agreement']"></div>
                        </template>
                        <div
                            class="notes text-xs"
                            v-html="requestMentorshipNotes">
                        </div>
                    </n-collapse-item>
                </n-collapse>
            </template>
        </n-alert>

        <rb-input
            class="mt-4"
            :label="needHelpModel.subject.title"
            :placeholder="needHelpModel.subject.placeholder"
            :status="needHelpModel.subject.status === 'error' ? 'error' : undefined"
            :msg="needHelpModel.subject.msg"
            v-model:value="needHelpModel.subject.value"
            @update:value="needHelpModel.subject.status = undefined, needHelpModel.subject.msg = undefined" />

        <div
            class="mt-4"
            :class="needHelpModel.question.status === 'error' ? 'border border-red-400' : ''">
            <quill-editor
                theme="snow"
                contentType="html"
                v-model:content="needHelpModel.question.value">
            </quill-editor>
            <div v-if="needHelpModel.question.status === 'error'" class="w-full mt-2 text-red-400 text-right">{{ needHelpModel.question.msg }}</div>
        </div>

        <rb-input
            class="mt-4"
            :label="needHelpModel.contact.title"
            :placeholder="needHelpModel.contact.placeholder"
            :status="needHelpModel.contact.status === 'error' ? 'error' : undefined"
            :msg="needHelpModel.contact.msg"
            v-model:value="needHelpModel.contact.value"
            @update:value="needHelpModel.contact.status = undefined, needHelpModel.contact.msg = undefined" />
    </template>
    <template #footer>
        <div class="flex justify-end items-center">
            <rb-checkbox
                :label="needHelpModel.agree.placeholder"
                :status="needHelpModel.agree.status === 'error' ? 'error' : undefined"
                v-model:checked="needHelpModel.agree.value"
                @update:checked="needHelpModel.agree.status = null" />
            <n-button
                strong
                class="rounded-md text-white/90 ml-4"
                :color="gl.mainColor"
                :loading="needHelpLoading"
                :disabled="buttonDisabled || needHelpLoading"
                @click="requestMentorship">
                {{ marketplace.localization['marketplace/mentors/dialogs/request_mentorship'] }}
            </n-button>
        </div>
    </template>
</base-modal-desktop>
</template>

<script>
// general
import general from './general';

// icons
import { Warning24Filled, Dismiss16Filled } from '@vicons/fluent';

// UI
import {
    NIcon,
    NCard,
    NTabs,
    NSpin,
    NAlert,
    NModal,
    NInput,
    NButton,
    NTabPane,
    NCheckbox,
    NCollapse,
    NCollapseItem } from 'naive-ui';

// components
import SuperTable from '@components/super-table/desktop.vue';

// editor
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
    name: 'mentors-desktop',
    components: {
        NSpin,
        NIcon,
        NCard,
        NTabs,
        NAlert,
        NModal,
        NInput,
        NButton,
        NTabPane,
        NCollapse,
        NCheckbox,
        SuperTable,
        QuillEditor,
        NCollapseItem,
        Dismiss16Filled,
        Warning24Filled,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>